.curtain {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
align-content: center; 
padding-left: 6px;
padding-right: 6px;
padding-bottom: 28px;
position: relative;
}

.curtainLoader {
    width: 65px;
    height: 65px;
    animation: rotation 3s linear infinite;
    margin-bottom: 50px;
    margin-top: 66px;
}

.curtainLoader svg {
    width: 100%;
    height: 100%;
}

.curtainTitle {
    font-family: 'Inter';
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
    max-width: 280px;
    margin-bottom: 6px;
    color: var(--bright-text-color)
}

.curtainSubtitle {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 16.71px;
    max-width: 280px;
    text-align: center;
    color: var(--disabled-button-text);
    margin-bottom: 32px;
}

.curtainSubtitle.smallMargin {
    margin-bottom: 12px;
}

.button {
    width: calc(100% - 64px);
    height: 54px;
    background-color: var(--banner-count-background-color);
    color: var(--bright-text-color);
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    border-radius: 100px;
    cursor: pointer;
}

.closeButton {
    position: absolute;
    top: -6px;
    right: 16px;
    height: 16px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    cursor: pointer;
    background-color: var(--main-cards-bg);
}

.closeButton:after{
    content: '';
    width: 100%;
    width: 12px;
    height: 1.5px;
    background-color: var(--bright-text-color);
    position: absolute;
    left: 6px;
    transform: rotate(45deg);
}

.closeButton:before{
    content: '';
    width: 100%;
    width: 12px;
    height: 1.5px;
    background-color: var(--bright-text-color);
    position: absolute;
    right: 6px;
    transform: rotate(-45deg);
}

.curtainReject {
    width: 65px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-bottom: 50px;
    margin-top: 46px;
}

.curtainReject:after {
    content: '';
    width: 80px;
    height: 8px;
    position: absolute;
    display: block;
    border-radius: 10px;
    background-color: var(--bright-red);
    transform: rotate(45deg);
}

.curtainReject:before {
    content: '';
    width: 80px;
    height: 8px;
    position: absolute;
    display: block;
    border-radius: 10px;
    background-color: var(--bright-red);
    transform: rotate(-45deg);
}

.curtainSuccess {
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    align-content: center;
    position: relative;
    margin-bottom: 30px;
    margin-top: 46px;
}

.curtainSuccess:before {
    content: '';
    width: 36px;
    height: 8px;
    position: absolute;
    flex-shrink: 0;
    display: block;
    top: 28px;
    left: -8px;
    border-radius: 10px;
    background-color: var(--tag-dark-green);
    transform: rotate(45deg);
}

.curtainSuccess:after {
    content: '';
    width: 65px;
    height: 8px;
    position: absolute;
    flex-shrink: 0;
    top: 18px;
    right: -8px;
    display: block;
    border-radius: 10px;
    background-color: var(--tag-dark-green);
    transform: rotate(-45deg);
}

.curtainBottomContent {
    display: flex;
    align-items: center;
    width: 100%;
    align-content: center;
}

.rejectButton {
    width: 54px;
    height: 54px;
    margin-left: 10px;
    flex-shrink: 0;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    background-color: var(--banner-count-background-color);
    position: relative;
}

.rejectButton:after{
    content: '';
    width: 20px;
    height: 1px;
    background-color: var(--bright-text-color);
    position: absolute;
    transform: rotate(45deg);
}

.rejectButton:before{
    content: '';
    width: 20px;
    height: 1px;
    background-color: var(--bright-text-color);
    position: absolute;
    transform: rotate(-45deg);
}

.backToLoadingButtom {
    width: 54px;
    height: 54px;
    margin-right: 10px;
    flex-shrink: 0;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    background-color: var(--banner-count-background-color);
    position: relative;
}

@keyframes rotation {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}