.wrapper {
    width: calc(100% + 8px);
    margin-left: -4px;
    margin-right: -4px;
    overflow: hidden;
    height: 283px;
}

.title {
    width: 100%;
    padding-left: 4px;
    font-weight: 500;
    padding-right: 4px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.text {
    font-size: 20px;
    line-height: 20px;
    color: var(--bright-text-color);
    margin-right: 4px; 
}

.count {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: var(--items-count);
    margin-top: 2px;
}

.scroll {
    overflow-y: hidden;
    overflow-x: scroll;
    padding-bottom: 10px;
    overscroll-behavior: auto;
    display: flex;
}