.wrapper {
    width: 100%;
    height: 108px;
    position: fixed;
    left: 0;
    bottom: -108px;
    background-color: var(--background-global);
    padding: 16px 8px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    transition: all .15s;

    z-index: 3;
}

.wrapper.opened {
    bottom: 0;
}

.button {
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    padding-left: 24px;
    cursor: pointer;
    padding-right: 24px;
    border-radius: 99px;
   
    justify-content: center;
}

.button.spaceBetween {
    justify-content: space-between;
}

.button span {
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
    letter-spacing: -0.23999999463558197px;
    color: var(--bright-text-color);
}