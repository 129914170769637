.wrapper {
    padding: 16px 8px;
}

.title {
    font-size: 24px;
    font-weight: 600;
    line-height: 26.4px;
    letter-spacing: -0.01em;
    text-align: left;
    font-variation-settings: 'wdth' 40, 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738;
    color: var(--bright-text-color);
    margin-bottom: 4px;
}

.more {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--switcher-active-color);
    position: relative;
}

.more span {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: var(--switcher-active-color)
}

.arrow {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 11.5px;
    position: relative;
    margin-left: 6px;
    transform: rotate(-90deg);
    margin-left: 4px;
    min-width: 11.5px;
    margin-top: 2px;
}

.arrow:after{
    content: '';
    width: 7px;
    height: 1px;
    background-color: var(--switcher-active-color);
    position: absolute;
    left: 0;
    transform: rotate(45deg);
}

.arrow:before{
    content: '';
    width: 7px;
    height: 1px;
    background-color: var(--switcher-active-color);
    position: absolute;
    right: 0;
    transform: rotate(-45deg);
}

.need {
    margin-top: 16px;
    width: 100%;
}