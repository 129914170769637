.wrapper {
    width: 100%;
    height: 260px;
    border-radius: 12px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    background-color: wheat;
}