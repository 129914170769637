.wrapper {
    --default-transform: .45s
}

.innerWrapper {
    overflow: hidden;
    position: absolute;
    bottom: 0;
    z-index: 11;
    width: calc(100% + 16px);
    left: -8px;
    right: -8px;
    transition: all var(--default-transform);
    overscroll-behavior: contain;
}


.content {
    padding: 24px 8px;
    background-color: var(--main-cards-bg);
    border-radius: 24px 24px 0 0;
    transition: all var(--default-transform);
}

.opened .content {
    background-color: var(--background-global);
}

.bg {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    backdrop-filter: blur(10px) brightness(0.4);
    transition: opacity var(--default-transform), z-index .001s var(--default-transform);

    z-index: -1;
    opacity: 0;
}

.opened .bg {
    z-index: 10;
    opacity: 1;
    transition: opacity var(--default-transform);
}

.title {
    display: flex;
    position: relative;
    margin-bottom: 16px;
}


.title .innerTitle{
    color: var(--bright-text-color);
    font-size: 24px;
    font-weight: 600;
    line-height: 26.4px;
    letter-spacing: -0.01em;
    font-variation-settings: 'wdth' 40, 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738;
    text-transform: uppercase;
    position: relative;
    display: block;
    left: calc(50% - 160px / 2);
    transition: all var(--default-transform);

    display: flex;
    align-items: center;
}

.spanText {
    opacity: 0.4;
    transition: all var(--default-transform);
    margin-right: 8px;
}

.opened .spanText{
    opacity: 1;
}

.svg {
    opacity: 1;
    transition: all var(--default-transform);
}

.opened .svg {
    opacity: 0;
}

.opened .title .innerTitle {
    left: 16px;
}

.list {
    opacity: 0;
    transition: all var(--default-transform);
}

.opened .list {
    opacity: 1;
}

.courseWrapper:not(:last-child) {
    margin-bottom: 10px;
}

.exitButton {
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    background-color: var(--main-cards-bg);

    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;

    top: 16px;
    right: 16px;

    opacity: 0;
    transition: all var(--default-transform);
}

.opened .exitButton {
    opacity: 1;
}

.exitButton:after,
.exitButton:before {
    content: '';
    width: 14px;
    position: absolute;
    height: 1px;
    background-color: var(--bright-text-color);
    display: block;
    transform: rotate(45deg);
}
.exitButton:after {
    transform: rotate(-45deg);
}