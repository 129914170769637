.wrapper {
    padding-left: 24px;
    padding-right: 24px;
    position: relative;
}

.title {
    margin-bottom: 12px;
}

.titleText {
    font-size: 24px;
    font-weight: 600;
    line-height: 26.4px;
    letter-spacing: -0.01em;
    text-align: left;
    font-variation-settings: 'wdth' 40, 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738;
    color: var(--bright-text-color);
}

.description {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin-top: 12px;
    text-align: left;
    color: var(--bright-text-color);
}

.button {
    width: 100%;
    margin-top: 40px;
    padding: 16px;
    border-radius: 100px;
    background-color: var(--banner-count-background-color);

    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'Inter';
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: var(--bright-text-color)

} 
.exitButton {
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    background-color: var(--main-cards-bg);

    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;

    top: -8px;
    right: 16px;

    opacity: 1;

    transition: all var(--default-transform);
}


.exitButton:after,
.exitButton:before {
    content: '';
    width: 14px;
    position: absolute;
    height: 1px;
    background-color: var(--bright-text-color);
    display: block;
    transform: rotate(45deg);
}
.exitButton:after {
    transform: rotate(-45deg);
}