.wrapper {
    width: 100%;
    padding: 16px;
    border-radius: 14px;
    background-color: var(--main-cards-bg);
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.avatar {
    position: relative;
    width: 56px;
    height: 56px;
    border-radius: 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-right: 16px;
}

.stub {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;

    font-family: 'Inter';
    font-size: 28px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;

    border-radius: 100px;
    background-color: var(--banner-count-background-color);
    color: var(--bright-text-color);
}

.inputLabel {
    width: 22px;
    height: 22px;
    position: absolute;
    border-radius: 100px;
    border: 2px solid var(--main-cards-bg);
    background-color: var(--bright-text-color);

    display: flex;
    align-items: center;
    justify-content: center;

    bottom: -2px;
    right: -2px;
}

.inputLabel svg {
    width: 10px;
    position: absolute;
    height: 10px;
}

.input {
    width: 100%;
    height: 100%;
    opacity: 0;
}

.content {
    width: calc(100% - 72px);
}

.realName {
    width: 100%;
    margin-bottom: 4px;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.23999999463558197px;
    text-align: left;
    margin-bottom: 4px;
    color: var(--bright-text-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.userName {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: var(--user-name-text);
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}