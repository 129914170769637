.wrapper {
    padding-left: 24px;
    padding-right: 24px;
    position: relative;
}

.title {
    margin-bottom: 12px;
}

.titleText {
    font-size: 32px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.01em;
    text-align: left;
    font-variation-settings: 'GRAD' 0, 'XOPQ' 96, 'XTRA' 468, 'YOPQ' 79, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738, 'YTLC' 514, 'YTUC' 712, 'slnt' 0, 'wdth' 25;
    color: var(--tag-purple);
    text-transform: uppercase;

}

.description {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: var(--bright-text-color);
    margin-bottom: 24px;
}

.recomendation {
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 12px;
}

.exitButton {
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    background-color: var(--main-cards-bg);

    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;

    top: -8px;
    right: 16px;

    opacity: 1;

    transition: all var(--default-transform);
}


.exitButton:after,
.exitButton:before {
    content: '';
    width: 14px;
    position: absolute;
    height: 1px;
    background-color: var(--bright-text-color);
    display: block;
    transform: rotate(45deg);
}
.exitButton:after {
    transform: rotate(-45deg);
}