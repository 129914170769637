.wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 8px;
    min-height: 100vh;
    align-items: flex-start;
    align-content: flex-start;
}

.cell {
    height: 151px;
    width: 100%;
    margin-bottom: 4px;
    border-radius: 24px;
    background-color: var(--main-cards-bg);
    animation: stub 2s linear infinite;
}

.cell:first-child {
    margin-bottom: 49px;
    height: 171px;
    display: flex;
    justify-content: center;
    position: relative;
}

.cell:first-child::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -25px;
    width: calc(100% - 48px);
    height: 1px;
    background-color: var(--disabled-button-background);
}

@keyframes stub {
    0% {
        background-color: var(--main-cards-bg);
    }

    40% {
        background-color: var(--background-global);
    }

    60% {
        background-color: var(--background-global);
    }

    100% {
        background-color: var(--main-cards-bg);
    }
}