.wrapper {
    width: 100%;
    position: relative;
}

.text {
    display: -webkit-box;
    width: 100%;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: var(--bright-text-color);
    margin-bottom: 8px;

    transition: all .15s;
}

.button {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    cursor: pointer;
    text-align: left;
    color: var(--switcher-active-color);
    display: flex;
    align-items: center;
}

.arrow {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 11.5px;
    position: relative;
    margin-left: 6px;
}

.arrow:after{
    content: '';
    width: 100%;
    width: 7px;
    height: 1px;
    background-color: var(--switcher-active-color);
    position: absolute;
    left: 0;
    transform: rotate(45deg);
}

.arrow:before{
    content: '';
    width: 100%;
    width: 7px;
    height: 1px;
    background-color: var(--switcher-active-color);
    position: absolute;
    right: 0;
    transform: rotate(-45deg);
}