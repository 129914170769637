.wrapper {
    padding: 16px;
    background-color: var(--main-cards-bg);
    border-radius: 20px;
    overflow: hidden;
}

.subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.01em;
    font-family: 'Inter', sans-serif;
    font-variation-settings: 'GRAD' 0, 'XOPQ' 96, 'XTRA' 468, 'YOPQ' 79, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738, 'YTLC' 514, 'YTUC' 712, 'slnt' 0, 'wdth' 100;
    color: var(--switcher-disabled-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header {
    margin-bottom: 16px;
}

.upperCase {
    text-transform: uppercase;
    margin-bottom: 12px;
}

.title {
    font-size: 24px;
    font-weight: 600;
    line-height: 26.4px;
    letter-spacing: -0.01em;
    text-align: left;
    font-variation-settings: 'wdth' 40, 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738;


    color: var(--bright-text-color);
    margin-bottom: 8px;
}

.title.interTitle {
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
}

.itemTitle.disabled {
    color: var(--switcher-disabled-color);
}

.item {
    text-decoration: none;
    display: flex;
    padding: 10px 0;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--switcher-active-bg-color);
}

.item:last-child {
    padding-bottom: 16px;
}

.item:first-child {
    padding-top: 0;
}

.itemTitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    font-family: 'Inter', sans-serif;

    color: var(--bright-text-color);
}

.arrow {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 11.5px;
    position: relative;
    margin-left: 6px;
    transform: rotate(-90deg);
    margin-left: 8px;
    min-width: 11.5px;
}

.arrow:after{
    content: '';
    width: 7px;
    height: 1px;
    background-color: var(--switcher-disabled-color);
    position: absolute;
    left: 0;
    transform: rotate(45deg);
}

.arrow:before{
    content: '';
    width: 7px;
    height: 1px;
    background-color: var(--switcher-disabled-color);
    position: absolute;
    right: 0;
    transform: rotate(-45deg);
}

.arrow.completedArrow {
    transform: rotate(0);
    width: 8px;
}

.arrow.completedArrow:after,
.arrow.completedArrow:before {
    background-color: var(--tag-dark-green);

}

.arrow.completedArrow:after {
    width: 5px;
    left: 1.5px;
    top: 0.5px;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 16px;
    font-weight: 500;

    color: var(--banner-count-background-color)
}

.buttonArrow {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 14.5px;
    position: relative;
    margin-left: 6px;
    transform: rotate(-90deg);
    margin-left: 8px;
    min-width: max-content;
}

.buttonArrow:after{
    content: '';
    width: 100%;
    width: 9px;
    height: 1.5px;
    background-color: var(--banner-count-background-color);
    position: absolute;
    left: 0;
    transform: rotate(45deg);
}

.buttonArrow:before{
    content: '';
    width: 100%;
    width: 9px;
    height: 1.5px;
    background-color: var(--banner-count-background-color);
    position: absolute;
    right: 0;
    transform: rotate(-45deg);
}

.buttonWrapper {
    width: calc(100% + 32px);
    margin-left: -16px;
    margin-right: -16px;
    padding-top: 16px;
    margin-top: -1px;
    border-top: 1px solid var(--background-global);
}