.wrapper {
    height: 100vh;
}

.image {
    height: 260px;
    position: relative;
    background-color: var(--main-cards-bg);
}

.content {
    margin-top: -82px;
    height: calc(100vh - 178px);
    padding: 8px;
    padding-top: 24px;
    border-radius: 20px;
    position: relative;
    background-color: var(--background-global);

    z-index: 1;
}

.title {
    height: 32px;
    width: 150px;
    margin-left: 16px;
    border-radius: 20px;
    margin-bottom: 16px;
}

.subtitle {
    height: 20px;
    width: 60px;
    border-radius: 20px;
    margin-left: 16px;
    margin-bottom: 12px;
}

.description {
    width: calc(100% - 32px);
    margin-left: auto;
    margin-right: auto;
    height: 63px;
    border-radius: 12px;
}

.animation {
    background-color: var(--main-cards-bg);
    animation: stub 2s linear infinite;
}


@keyframes stub {
    0% {
        background-color: var(--main-cards-bg);
    }

    40% {
        background-color: var(--background-global);
    }

    60% {
        background-color: var(--background-global);
    }

    100% {
        background-color: var(--main-cards-bg);
    }
}