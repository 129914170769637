.wrapper {
    width: calc(50% - 2px);
    height: 150px;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    padding: 16px;
    background-color: var(--main-cards-bg);
    border-radius: 24px;
    position: relative;
    text-decoration: none;
    margin-bottom: 4px;
}

.name {
    max-width: 104px;
    color: var(--bright-text-color);
    font-size: 18px;
    text-decoration: none;
    font-weight: 600;
    letter-spacing: -0.01em;
    text-align: left;
    font-variation-settings: 'wdth' 25, 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738;
}

.count {
    position: absolute;
    bottom: 16px;
    left: 16px;
    width: 32px;
    height: 32px;
    background-color: var(--switcher-active-bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--number-text-color);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.01em;
    text-align: center;
    border-radius: 50%;
}