.wrapper {
    width: 100%;
    padding: 16px;
    border-radius: 24px;
    height: fit-content;
    position: relative;
    background-color: var(--main-cards-bg);
    text-decoration: none;
    display: block;
    margin-bottom: 4px;
}

.defaultCursor {
    cursor: default;
}


.tagWrapper {
   margin-bottom: 12px; 
}

.title {
    color: var(--bright-text-color);
    font-size: 24px;
    font-weight: 600;
    line-height: 26.4px;
    letter-spacing: -0.01em;
    text-align: left;
    font-variation-settings: 'wdth' 40, 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738;
    margin-bottom: 15px;
}

.top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}

.description {
    font-size: 14px;
    line-height: 19.6px;
    text-transform: uppercase;
    letter-spacing: -0.01em;
    font-variation-settings: 'wdth' 90, 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738;
    color: var(--switcher-disabled-color);
}

.count {
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    letter-spacing: -0.01em;
    font-variation-settings: 'wdth' 90, 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738;
    color: var(--switcher-disabled-color);
}

.bottom {
    width: 100%;
    position: relative;
    height: 8px;
    border-radius: 30px;
    display: flex;
    align-items: flex-start;
    background-color: var(--switcher-active-bg-color);
}

.progress {
    height: 100%;
    border-radius: 30px;
    background-color: var(--switcher-active-color);
}

