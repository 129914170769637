.wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.cell {
    height: 171px;
    width: 100%;
    margin-bottom: 4px;
    border-radius: 24px;
    background-color: var(--main-cards-bg);
    animation: stub 2s linear infinite;

}

@keyframes stub {
    0% {
        background-color: var(--main-cards-bg);
    }

    40% {
        background-color: var(--background-global);
    }

    60% {
        background-color: var(--background-global);
    }

    100% {
        background-color: var(--main-cards-bg);
    }
}