.wrapper {
    height: 100vh;
    padding-top: 8px;
}
.video {
    width: calc(100% - 16px);
    margin-left: auto;
    margin-right: auto;
    height: 200px;
    border-radius: 16px;
    margin-bottom: 16px;
}

.title {
    width: 140px;
    height: 26px;
    border-radius: 20px;
    margin-left: 16px;
    margin-bottom: 4px;
}

.more {
    width: 80px;
    height: 21px;
    border-radius: 20px;
    margin-left: 16px;
    margin-bottom: 16px;
}

.subtitle {
    width: 120px;
    height: 24px;
    border-radius: 20px;
    margin-bottom: 8px;
    margin-left: 16px;
}

.items {
    width: calc(100% - 8px);
    margin-left: 8px;
    display: flex;
    overflow: hidden;
}

.item {
    width: 200px;
    height: 235px;
    border-radius: 16px;
    margin-right: 4px;
    flex-shrink: 0;
}

.animation {
    background-color: var(--main-cards-bg);
    animation: stub 2s linear infinite;
}

@keyframes stub {
    0% {
        background-color: var(--main-cards-bg);
    }

    40% {
        background-color: var(--background-global);
    }

    60% {
        background-color: var(--background-global);
    }

    100% {
        background-color: var(--main-cards-bg);
    }
}