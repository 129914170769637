.wrapper {
    padding: 16px;
    background-color: var(--main-cards-bg);
    border-radius: 24px;
    padding-bottom: 8px;
    display: block;
    text-decoration: none;
    text-transform: none;
}

.title {
    font-family: Roboto Flex;
    font-size: 24px;
    font-weight: 600;
    line-height: 26.4px;
    letter-spacing: -0.01em;
    text-align: left;
    font-variation-settings: 'wdth' 40, 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738;
    color: var(--bright-text-color);
}

.chipList {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
}

.chipWrapper {
    margin-bottom: 8px;
}

.chipWrapper:not(:last-child) {
    margin-right: 8px;
}