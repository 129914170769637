.wrapper {
    width: 100%;
    background-color: var(--main-cards-bg);
    border-radius: 24px;
    padding: 20px 24px;
    margin-bottom: 24px;
    background-position: 89% -12px;
    background-repeat: no-repeat;
    background-size: 113px;
}

.title {
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    text-transform: uppercase;
    letter-spacing: -0.01em;
    text-align: left;
    color: var(--bright-text-color);
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 2px;
    font-variation-settings: 'wdth' 25, 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738;    
}

.bottom {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.description {
    font-size: 12px;
    font-weight: 500;
    color: var(--items-count);
    line-height: 15.6px;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    font-variation-settings: 'wdth' 90, 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738;
}