.wrapper {
    padding: 8px;
    min-height: 100vh;
}

.banner {
    width: 100%;
    border-radius: 20px;
    height: 120px;
    margin-bottom: 24px;
}

.title {
    width: 100px;
    height: 20px;
    margin-bottom: 8px;
    border-radius: 10px;
}

.slider {
    width: 100%;
    display: flex;
    overflow: hidden;
}

.item {
    height: 264px;
    min-width: 200px;
    width: 200px;
    border-radius: 20px;
    margin-right: 4px;
}

.animation {
    background-color: var(--main-cards-bg);
    animation: stub 2s linear infinite;
}



@keyframes stub {
    0% {
        background-color: var(--main-cards-bg);
    }

    40% {
        background-color: var(--background-global);
    }

    60% {
        background-color: var(--background-global);
    }

    100% {
        background-color: var(--main-cards-bg);
    }
}